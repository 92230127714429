import {
  EditorReadyFn,
  HandleActionFn,
  OnEventFn,
  GetAppManifestFn,
  FlowAPI,
} from '@wix/yoshi-flow-editor';

import {
  ADD_VIDEO_ELEMENT,
  CHANNELS_SECTION,
  LIVE_STREAM,
  LIVE_STREAM_SECTION,
  MANAGE_CHANNELS,
  MANAGE_VIDEOS,
  OPEN_CHANNELS_TAB,
  OPEN_LIVE_STREAM_TAB,
  OPEN_VIDEO_MANAGER_IN_BM,
  OPEN_VIDEO_SECTION_IN_ADD_PANEL,
  PAID_PLANS_APP_DEF_ID,
  UPGRADE_LINK_TEXT,
  UPGRADE_TEXT,
  VIDEOS_SECTION,
} from './constants';
import { VIDEO_APP_DEF_ID } from '@wix/wix-vod-constants/dist/common/app-def-id';
import { WIDGET_ID } from '@wix/wix-vod-constants/dist/common/widget-id';

let appToken: string;

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
) => {
  // I don't know wtf is going on but here in Autopilot should be the token instead of appDefinitionId
  // https://github.com/wix-private/autopilot/blob/c678a7bdcd741a890f82d796923354754e4076db/docs/installEditorScriptExample.md
  appToken = appDefinitionId;
};

export const getAppManifest: GetAppManifestFn = (
  options,
  editorSDK,
  contextParams,
  flowAPI: FlowAPI,
) => {
  const { appManifestBuilder } = options;

  appManifestBuilder.configureManagementActions((builder) => {
    const customActions = builder.customActions();
    const mainActions = builder.mainActions();
    const upgradeAction = builder.upgradeAction();

    mainActions.addAction({
      title: flowAPI.translations.t(MANAGE_VIDEOS),
      actionId: OPEN_VIDEO_MANAGER_IN_BM,
      icon: 'appManager_settingsAction',
    });

    customActions.addAction({
      title: flowAPI.translations.t(ADD_VIDEO_ELEMENT),
      actionId: OPEN_VIDEO_SECTION_IN_ADD_PANEL,
      icon: 'appManager_addElementsAction',
      type: 'editorActions',
    });

    customActions.addAction({
      title: flowAPI.translations.t(MANAGE_CHANNELS),
      actionId: OPEN_CHANNELS_TAB,
      icon: 'appManager_settingsAction',
      type: 'dashboard',
    });

    customActions.addAction({
      title: flowAPI.translations.t(LIVE_STREAM),
      actionId: OPEN_LIVE_STREAM_TAB,
      icon: 'appManager_settingsAction',
      type: 'dashboard',
    });

    upgradeAction.set({
      upgradeType: 'SITE_UPGRADE',
      upgradeText: flowAPI.translations.t(UPGRADE_TEXT) + ' ',
      upgradeLinkText: flowAPI.translations.t(UPGRADE_LINK_TEXT),
    });
  });

  appManifestBuilder.configureWidget(WIDGET_ID, (widgetBuilder) => {
    widgetBuilder.gfpp().set('mainAction1', {
      behavior: 'DEFAULT',
      permissions: {
        custom: ['WIX_VIDEO.MANAGE_LIBRARY'],
      },
    });
  });

  return appManifestBuilder.build();
};

export const onEvent: OnEventFn = async ({ eventPayload }, { editor }) => {
  const { show } = editor.deeplink;

  try {
    switch (eventPayload.actionId) {
      case OPEN_VIDEO_MANAGER_IN_BM:
        editor.openDashboardPanel(VIDEO_APP_DEF_ID, {
          url: VIDEOS_SECTION,
          closeOtherPanels: false,
        });
        break;
      case OPEN_VIDEO_SECTION_IN_ADD_PANEL:
        show('', {
          type: 'addPanel',
          named: {
            appDefinitionId: VIDEO_APP_DEF_ID,
          },
        });
        break;
      case OPEN_LIVE_STREAM_TAB:
        editor.openDashboardPanel(VIDEO_APP_DEF_ID, {
          url: LIVE_STREAM_SECTION,
          closeOtherPanels: false,
        });
        break;
      case OPEN_CHANNELS_TAB:
        editor.openDashboardPanel(VIDEO_APP_DEF_ID, {
          url: CHANNELS_SECTION,
          closeOtherPanels: false,
        });
        break;
      default:
        return;
    }
  } catch (e) {
    console.warn('Wix Video - EditorScript OnEvent Error');
  }
};

export const handleAction: HandleActionFn = async (
  { type, payload },
  editorSDK,
) => {
  if (type === 'migrate' && (payload as any)?.addPaidPlans) {
    return editorSDK.document.tpa.add.application(appToken, {
      appDefinitionId: PAID_PLANS_APP_DEF_ID,
    });
  }
};
